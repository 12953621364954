import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/quote-list.module.css";

function QuoteList({ items }) {
  return (
    <div className={styles.root}>
      {items.map(({ _key, description, logo }) => {
        return (
          <>
          {description && (
            <div key={_key} className={styles.item}>
              <p>{description}</p>
              {logo && logo.asset && (
                <img
                  src={imageUrlFor(buildImageObj(logo))
                    .auto("format")
                    .url()}
                  alt={logo.alt}
                />
              )}
            </div>
          )}
          </>
        );
      })}
    </div>
  );
}

export default QuoteList;
