import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/book-content-block-2.module.css";

const ContentBlock = (props) => {

  const {
    description,
    image,
    link
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.flexGrid} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
          <div className={styles.text}>
            {description && (
              <p className="h3">
                {description}
              </p>
            )}
          </div>
          {link && link.linkUrl && (
            <div className={styles.featuredLink} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
              {link.externalContent ? 
                (
                  <a href={link.linkUrl} target="_blank" rel="noreferrer">
                    {image && image.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .auto("format")
                          .url()}
                        alt={image.alt}
                      />
                    )}
                    {link.text && (
                      <span className="h2">{link.text}</span>
                    )}
                  </a>
                ) : (
                  <Link to={`${link.linkUrl}`}>
                  {image && image.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .auto("format")
                          .url()}
                        alt={image.alt}
                      />
                    )}
                    {link.text && (
                      <span className="h2">{link.text}</span>
                    )}
                  </Link> 
                )
              }
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ContentBlock;
