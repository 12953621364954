import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/book-formats-2.module.css";

function BookFormats({ items }) {
  return (
    <div className={styles.root} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      {items.map(({ _key, title, cover, bookFile, linkUrl }) => {
        return (
          <div key={_key} className={styles.item}>
            <a href={linkUrl ? linkUrl : bookFile.asset.url} target="_blank" rel="noreferrer">
              {cover && cover.asset && (
                <img
                  src={imageUrlFor(buildImageObj(cover))
                    .auto("format")
                    .url()}
                  alt={cover.alt}
                />
              )}
              {title && (
                <span className="h4">{title}</span>
              )}
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default BookFormats;
