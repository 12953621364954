import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import FeaturedBlock from "../components/book-featured-block-2";
import ContentBlock from "../components/book-content-block-2";
import ChaptersCarousel from "../components/book-chapters-carousel";
import WaysToRead from "../components/book-ways-to-read-2";
import Quotes from "../components/book-quotes-2";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BookNewPageQuery {
    bookNew: sanityBookNew {
      bookFeaturedBlock {
        _rawHeading
        topSubheading
        bottomSubheading
        description
        image {
          ...SanityImage
          alt
        }
      }
      bookContentBlock {
        description
        image {
          ...SanityImage
          alt
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
      _rawChaptersHeading
      bookWaysToRead {
        heading
        waysToRead {
          _key
          bookFile {
            asset {
              _id
              url
            }
          }
          linkUrl
          title
          cover {
            ...SanityImage
            alt
          }
        }
      }
      bookQuotes {
        heading
        quotes {
          _key
          description
          logo {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const BookNewPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const bookNew = (data || {}).bookNew;

  return (
    <Layout>
      <Seo
        title="lululemon and the future of technical apparel"
        description={bookNew.bookFeaturedBlock.description}
      />

      {bookNew.bookFeaturedBlock && (
        <FeaturedBlock {...bookNew.bookFeaturedBlock} />
      )}

      {bookNew.bookWaysToRead && (
        <WaysToRead {...bookNew.bookWaysToRead} />
      )}

      {bookNew.bookContentBlock && (
        <ContentBlock {...bookNew.bookContentBlock} />
      )}

      {/*<ChaptersCarousel title={book._rawChaptersHeading} />*/}

      {bookNew.bookQuotes && (
        <Quotes {...bookNew.bookQuotes} />
      )}
    </Layout>
  );
};

export default BookNewPage;
